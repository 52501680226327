
import Vue from 'vue';
import * as d3 from 'd3';
import CurrentSituationDropdown from './components/CurrentSituationDropdown.vue';
import BudgetDropdown from './components/BudgetDropdown.vue';
import RelationDropdown from './components/RelationDropdown.vue';
import DeadlineDropdown from './components/DeadlineDropdown.vue';
import DepartmentsDropdown from './components/DepartmentsDropdown.vue';
import Map from '@/components/Map.vue';
import Modal from '@/components/Modal.vue';
import { EstablishmentType } from '@/models/establishmentType';
import Axios from 'axios';

export default Vue.extend({
    name: 'Form',
    components: {
        RelationDropdown,
        DeadlineDropdown,
        CurrentSituationDropdown,
        BudgetDropdown,
        DepartmentsDropdown,
        Map,
        Modal,
    },
    data() {
        return {
            currentStep: 1,
            unsentForm: true,
            unsetFormCallback: null as any,
            showModalUnsentForm: false,
            showModalConditions: false,
            maxSelectedDepartments: 8, //Number maximum of departements that can be selected
            disabledBelgium: true, //If we want to disable Belgium from the departements selection
            establishmentType: EstablishmentType,
            selectedDepartments: [] as any[],
            showSearchLayer: false,
            infosFormInvalidField: [] as any,
            utmParameter: this.$route.query.utm ? this.$route.query.utm : '',
            contactForm: {
                lastName: '',
                name: '',
                email: '',
                phone: '',
                relation: '',
                ods: '',
                conditions: '',
            },
            infosForm: {
                gender: '',
                birthName: '',
                usageName: '',
                firstName: '',
                age: '',
                zipCode: '',
                currentSituation: '',
                deadline: '',
                budget: '',
            },
            contactFormInvalidField: [] as any,
            additionalInformation: '',
            isRunningAnimation: false,
        };
    },
    computed: {
        currentPercentage: function (): any {
            return `${(100 * this.currentStep) / 3}%`;
        },
        disableGoToStep2: function (): any {
            return (
                !this.contactForm.lastName ||
                !this.contactForm.name ||
                !this.contactForm.email ||
                !this.contactForm.phone ||
                !this.contactForm.conditions ||
                !this.contactForm.ods ||
                !this.contactForm.relation
            );
        },
        disableSendForm: function (): any {
            return (
                !this.infosForm.gender ||
                !this.infosForm.birthName ||
                !this.infosForm.firstName ||
                !this.infosForm.age ||
                !this.infosForm.zipCode ||
                !this.infosForm.currentSituation ||
                !this.infosForm.deadline ||
                !this.infosForm.budget
            );
        },
        showUsageName: function (): any {
            return this.infosForm && this.infosForm.gender && this.infosForm.gender == 'Mme' ? true : false;
        },
        selectedFranceDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        selectedBelgiumDepartments: function (): any {
            let department = this.selectedDepartments
                .filter((department) => {
                    if (!department.isFrance) {
                        return true;
                    }
                })
                .map((d) => d.uniqueId);
            return department || [];
        },
        selectedDepartmentsText: function (): any {
            let department = this.selectedDepartments.map((d) => `${d.value} - ${d.name}`);
            return department.join(', ');
        },
    },
    watch: {
        currentStep: function (val, oldVal) {
            if (val != oldVal) {
                window.scrollTo({ top: 0, behavior: 'smooth' });
                this.isRunningAnimation = true;
                setTimeout(() => {
                    this.isRunningAnimation = false;
                }, 600);
            }
        },
    },
    methods: {
        selectSvgRange(max: any, hasTransition: boolean) {
            let defaultColor = '#3298B0';
            let overColor = '#0B5177';

            for (let i = 8; i != 0; i--) {
                let color = i <= max ? overColor : defaultColor;
                d3.select(`#budgetPathId_${i}`)
                    .transition()
                    .duration(hasTransition ? 250 : 0)
                    .style('fill', color);
            }
        },
        closeUnsentForm() {
            this.showModalUnsentForm = false;
            this.unsetFormCallback = false;
        },
        departmentClicked(selectedDepartment: any) {
            if (
                selectedDepartment.isFrance &&
                this.selectedFranceDepartments.indexOf(selectedDepartment.uniqueId) != -1
            ) {
                this.selectedDepartments = this.selectedDepartments.filter(
                    (department) => department.uniqueId !== selectedDepartment.uniqueId
                );
                return;
            }

            if (this.selectedDepartments.length < this.maxSelectedDepartments) {
                this.selectedDepartments.push(selectedDepartment);
                return;
            }
        },
        goToStep(stepNumber: number) {
            if (this.currentStep == 2 && this.showSearchLayer) {
                return false;
            } else {
                this.showSearchLayer = false;
            }

            this.currentStep = stepNumber;
        },
        goToStep2() {
            this.contactFormInvalidField = [];
            if (!this.disableGoToStep2) {
                let frenchRegex = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;
                let belgiumRegex = /^(?:(?:\+|00)32|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/;

                if (!this.contactForm.phone.match(frenchRegex) && !this.contactForm.phone.match(belgiumRegex)) {
                    this.contactFormInvalidField.push('phone');
                }
                let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!this.contactForm.email.match(emailRegex)) {
                    this.contactFormInvalidField.push('email');
                }

                if (!this.contactFormInvalidField.length) {
                    this.currentStep = 2;
                }
            }
        },
        async sendForm() {
            this.infosFormInvalidField = [];
            if (!this.disableSendForm) {
                if (
                    !Number.isInteger(this.infosForm.age) &&
                    !(parseInt(this.infosForm.age) >= 1 && parseInt(this.infosForm.age) <= 200)
                ) {
                    this.infosFormInvalidField.push('age');
                }

                let franceZipCodeRegex = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/;
                let belgiumZipCodeRegex = /^[1-9]{1}[0-9]{3}$/;

                if (
                    !this.infosForm.zipCode.match(franceZipCodeRegex) &&
                    !this.infosForm.zipCode.match(belgiumZipCodeRegex)
                ) {
                    this.infosFormInvalidField.push('zipCode');
                }

                if (!this.infosFormInvalidField.length) {
                    this.isRunningAnimation = true;
                    this.showSearchLayer = true;

                    setTimeout(() => {
                        this.currentStep = 2;
                        this.showSearchLayer = false;
                    }, 5000);
                }
            }

            let url = 'https://urgence-maison-de-retraite.fr/apiForm.php';
            let ipUser = await Axios.get('https://api.ipify.org');

            Axios.get(url, {
                params: {
                    inputDep: this.selectedDepartments.map((d) => `${d.value} - ${d.name}`).join(','), // (1) 00 - Name (2+) 01 - Name1,02 - Name2
                    cnom: this.contactForm.lastName,
                    cprenom: this.contactForm.name,
                    ods: this.contactForm.ods,
                    cemail: this.contactForm.email,
                    contactlink: this.contactForm.relation, // relation.name
                    cphone: this.contactForm.phone,
                    consentementVal: this.contactForm.conditions ? 1 : 0, // 0 or 1
                    source: 'FUS',
                    emailAR: 'lead46',
                    userip: ipUser.data, // get IP from client

                    //Premium Fields
                    delai_urgence: this.infosForm.deadline,
                    ccomment: this.additionalInformation,
                    civilite_PA1: this.infosForm.gender,
                    paorigname: this.infosForm.usageName,
                    panom: this.infosForm.birthName,
                    paprenom: this.infosForm.firstName,
                    paydate: this.infosForm.age,
                    pazipcode: this.infosForm.zipCode,
                    pacurrentres: this.infosForm.currentSituation,
                    dispo: this.$route.params.establishmentType,
                    utm: this.utmParameter,
                    budget: this.infosForm.budget,
                },
            }).then(
                () => {
                    //Success
                    console.log('Success');
                    this.unsentForm = false;
                    this.$router.push({
                        name: 'ThankYou',
                        params: {
                            lastName: this.contactForm.lastName,
                            name: this.contactForm.name,
                            relation: this.contactForm.relation,
                        },
                    });
                },
                () => {
                    //Error
                    console.log('Error');
                }
            );
        },
    },
    beforeCreate(): any {
        if (!this.$route.params.establishmentType) {
            this.$router.push({ name: 'Home' }).catch(() => {});
        }
    },
    beforeRouteLeave(to, from, next) {
        this.showModalUnsentForm = true;
        if (this.unsentForm) {
            this.unsetFormCallback = () => next();
            next(false);
        } else {
            next();
        }
    },
});
