
import Vue from 'vue';

export default Vue.extend({
    name: 'Modal',
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        shouldCloseModal(event: any) {
            if (
                this.$refs.modalContainer &&
                this.showModal &&
                event.target != this.$refs.modalContainer &&
                !(this.$refs.modalContainer! as any).contains(event.target)
            ) {
                this.$emit('close');
            }
        },
    },
});
