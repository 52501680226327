<template>
    <article class="blog-card">
        <div class="blog-image-wrapper">
            <div class="blog-image">
                <img :src="article.image" :alt="article.title" />
            </div>
        </div>
        <div class="blog-content">
            <h3 class="blog-title">{{ article.title }}</h3>
            <div class="blog-preview">
                {{ truncateText(article.content, 260) }}
            </div>
            <router-link :to="{ name: 'BlogArticle', params: { id: article.id } }" class="read-more-btn">
                Lire la suite
            </router-link>
        </div>
    </article>
</template>

<script>
export default {
    name: 'BlogCard',
    props: {
        article: {
            type: Object,
            required: true,
        },
    },
    methods: {
        truncateText(content, maxLength) {
            if (!Array.isArray(content)) return '';

            let fullText = content.join(' ');
            if (fullText.length <= maxLength) return fullText;

            return fullText.substr(0, maxLength) + '...';
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/utils.scss';

.blog-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: $color-light-grayish-cyan;
    box-shadow: 0px 0px 12px #9b9b9b96;
    border: 0.5px solid rgba($color-moderate-cyan, 0.5);
    border-radius: 5px;
    overflow: hidden;

    .blog-image-wrapper {
        position: relative;
        width: 100%;
        padding-top: 80%;
    }

    .blog-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }

    .blog-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        padding: 20px;
    }

    .blog-title {
        margin: 0 0 15px 0;
        color: $color-moderate-cyan;
        @include fluid-prop('font-size', 1024px, 1920px, 22px, 26px);
        height: 4.5em;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .blog-preview {
        flex-grow: 1;
        margin-bottom: 20px;
        @include fluid-prop('font-size', 1024px, 1920px, 16px, 18px);
        line-height: 1.5;
        height: 7.5em;
        overflow: hidden;
    }

    .read-more-btn {
        align-self: flex-start;
        padding: 10px 20px;
        background-color: $color-moderate-cyan;
        color: white;
        text-decoration: none;
        border-radius: 5px;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: darken($color-moderate-cyan, 10%);
        }
    }
}
</style>
