
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Partners from './components/Partners.vue';
import Expertise from './components/Expertise.vue';
import BlogHome from './components/BlogHome.vue';
import Testimony from './components/Testimony.vue';
import Main from './components/Main.vue';
import NewsletterBanner from './components/NewsletterBanner.vue';

export default {
    name: 'Home',
    components: {
        Header,
        Partners,
        Expertise,
        BlogHome,
        Testimony,
        Footer,
        Main,
        NewsletterBanner,
    },
};
