import { render, staticRenderFns } from "./Testimony.vue?vue&type=template&id=0a1c6e55"
import script from "./Testimony.vue?vue&type=script&lang=ts"
export * from "./Testimony.vue?vue&type=script&lang=ts"
import style0 from "./Testimony.vue?vue&type=style&index=0&id=0a1c6e55&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports