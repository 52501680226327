<template>
    <div v-if="isLoading" class="loading">Chargement de l'article...</div>
    <div v-else-if="article" class="blog-article">
        <div class="article-header">
            <h1>{{ article.title }}</h1>
            <img :src="article.image" :alt="article.title" class="article-image" />
        </div>
        <div class="article-content">
            <p v-for="(paragraph, index) in article.content" :key="index">
                {{ paragraph }}
            </p>
        </div>
        <div class="article-footer">
            <router-link to="/" class="back-button">Retour à l'accueil</router-link>
        </div>
    </div>
    <div v-else class="article-not-found">Article non trouvé</div>
</template>

<script>
import { useBlogStore } from '@/store/blogStore';

export default {
    name: 'BlogArticle',
    data() {
        return {
            article: null,
            isLoading: true,
        };
    },
    async created() {
        const { getArticleById, fetchArticles } = useBlogStore();
        const id = Number(this.$route.params.id);
        this.article = getArticleById(id);
        if (!this.article) {
            await fetchArticles();
            this.article = getArticleById(id);
        }
        this.isLoading = false;
    },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/utils.scss';

.blog-article {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .article-header {
        margin-bottom: 2rem;
        text-align: center;

        h1 {
            font-size: 2.5rem;
            color: $color-moderate-cyan;
            margin-bottom: 1rem;
            margin-top: 8vh;
        }

        .article-image {
            width: 50%;
            max-width: 400px;
            object-fit: cover;
            border-radius: 8px;
            margin: 0 auto;
            display: block;
        }
    }

    .article-content {
        font-size: 1.1rem;
        line-height: 1.6;
        color: #333;
        margin-bottom: 2rem;
    }

    .article-footer {
        text-align: center;

        .back-button {
            display: inline-block;
            padding: 10px 20px;
            background-color: $color-moderate-cyan;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            font-weight: bold;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($color-moderate-cyan, 10%);
            }
        }
    }
}

.article-not-found {
    text-align: center;
    font-size: 1.5rem;
    color: #666;
    margin-top: 2rem;
}

@media (max-width: 768px) {
    .blog-article {
        padding: 1rem;

        .article-header {
            h1 {
                font-size: 2rem;
            }

            .article-image {
                width: 100%;
                height: auto;
            }
        }

        .article-content {
            font-size: 1rem;
        }
    }
}
</style>
