
import Vue from 'vue';

export default Vue.extend({
    name: 'RelationDropdown',
    props: ['selectedOptionId'],
    data() {
        return {
            isOpen: false,
            data: [
                {
                    id: 'Alain Bensoussan',
                    name: 'Alain Bensoussan',
                },
                {
                    id: 'Julie Pierret',
                    name: 'Julie Pierret',
                },
                {
                    id: 'Valérie Besson',
                    name: 'Valérie Besson',
                },
                {
                    id: 'Vincent Netter',
                    name: 'Vincent Netter',
                },
                {
                    id: 'Anne-Sophie Ruck',
                    name: 'Anne-Sophie Ruck',
                },
            ],
        };
    },
    computed: {
        placeholderText: function (): any {
            return this.selectedOption ? this.selectedOption.name : 'Choisissez une des options';
        },
        selectedOption: function (): any {
            let optionFound = this.data.find((option: any) => {
                if (option.id == this.selectedOptionId) {
                    return option;
                }
            });
            return optionFound || null;
        },
    },
    methods: {
        selectedItem(selectedItem: any) {
            this.$emit('update:selectedOptionId', selectedItem.id);
            setTimeout(() => {
                this.isOpen = false;
            }, 0);
        },
        closeDropdown(e: MouseEvent) {
            if (this.$refs.dropdown) {
                let targetElement: Node = e.target as Node;
                if (!(this.$refs.dropdown as any).contains(targetElement) && this.isOpen) {
                    this.isOpen = false;
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdown.bind(this));
    },
    activated() {
        document.addEventListener('click', this.closeDropdown.bind(this));
    },
    destroyed() {
        document.removeEventListener('click', this.closeDropdown.bind(this));
    },
    deactivated() {
        document.removeEventListener('click', this.closeDropdown.bind(this));
    },
});
