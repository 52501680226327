<template>
    <section class="blog-wrapper">
        <h2>Nos derniers <span>articles de blog</span></h2>

        <section v-if="isLoading" class="loading">Chargement des articles...</section>
        <section v-else-if="displayedArticles.length" class="blog-cards-wrapper">
            <BlogCard v-for="article in displayedArticles" :key="article.id" :article="article" />
        </section>
        <section v-else class="no-articles">Aucun article disponible pour le moment.</section>

        <div class="view-all-wrapper">
            <router-link to="/blog" class="view-all-btn">Consulter tous les articles de blog</router-link>
        </div>
    </section>
</template>

<script>
import BlogCard from './BlogCard.vue';
import { useBlogStore } from '../../../store/blogStore';

export default {
    name: 'BlogHome',
    components: {
        BlogCard,
    },
    data() {
        return {
            blogArticles: [],
            isLoading: true,
        };
    },
    computed: {
        displayedArticles() {
            return [...this.blogArticles].sort((a, b) => b.id - a.id).slice(0, 3);
        },
    },
    async created() {
        const { blogArticles, fetchArticles } = useBlogStore();
        if (!blogArticles.length) {
            await fetchArticles();
        }
        this.blogArticles = blogArticles;
        this.isLoading = false;
    },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/utils.scss';

.blog-wrapper {
    .view-all-wrapper {
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 2rem;

        .view-all-btn {
            display: inline-block;
            padding: 12px 24px;
            background-color: $color-moderate-cyan;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            font-weight: bold;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($color-moderate-cyan, 10%);
            }
        }
    }
    h2 {
        padding: 35px 55px;
        margin: 0;
        font-weight: normal;
        text-align: left;

        span {
            font-weight: bold;
        }

        @include for-phone-only {
            padding: 35px 20px;
        }
    }

    .blog-cards-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 2rem;
        margin: 0px 5vw 3rem 5vw;
    }
}
</style>
