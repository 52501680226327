<template>
    <div class="blog-page">
        <h1>Tous nos articles de blog</h1>
        <div class="article-header">
            <router-link to="/" class="back-button">Retour à l'accueil</router-link>
        </div>
        <section v-if="isLoading" class="loading">Chargement des articles...</section>
        <section v-else-if="sortedBlogArticles.length" class="blog-cards-wrapper">
            <BlogCard v-for="article in sortedBlogArticles" :key="article.id" :article="article" />
        </section>
        <section v-else class="no-articles">Aucun article disponible pour le moment.</section>
    </div>
</template>

<script>
import BlogCard from './BlogCard.vue';
import { useBlogStore } from '@/store/blogStore';

export default {
    name: 'BlogPage',
    components: {
        BlogCard,
    },
    data() {
        return {
            blogArticles: [],
            isLoading: true,
        };
    },
    async created() {
        const { blogArticles, fetchArticles } = useBlogStore();
        this.isLoading = true;
        if (!blogArticles.length) {
            await fetchArticles();
        }
        this.blogArticles = blogArticles;
        this.isLoading = false;
    },
    computed: {
        sortedBlogArticles() {
            return [...this.blogArticles].sort((a, b) => b.id - a.id);
        },
    },
};
</script>

<style scoped lang="scss">
@import '../../../assets/styles/variables.scss';
@import '../../../assets/styles/utils.scss';
.blog-page {
    padding: 2rem;

    h1 {
        text-align: center;
        margin-bottom: 2rem;
        margin-top: 8vh;
    }

    .blog-cards-wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        max-width: 1200px;
        margin: 0 auto;
        gap: 2rem;
        padding: 0 5vw 3rem;
    }

    .article-header {
        text-align: center;

        .back-button {
            margin-bottom: 2rem;
            display: inline-block;
            padding: 10px 20px;
            background-color: $color-moderate-cyan;
            color: white;
            text-decoration: none;
            border-radius: 5px;
            font-weight: bold;
            transition: background-color 0.3s ease;

            &:hover {
                background-color: darken($color-moderate-cyan, 10%);
            }
        }
    }
}
</style>
