
import Vue from 'vue';

export default Vue.extend({
    name: 'ThankYou',
    computed: {
        fullName: function (): string {
            return `${this.$route.params.lastName} ${this.$route.params.name},`;
        },
        relation: function (): string {
            return `${this.$route.params.relation}`;
        },
    },
    beforeCreate(): any {
        if (!this.$route.params.lastName || !this.$route.params.name || !this.$route.params.relation) {
            this.$router.push({ name: 'Home' }).catch(() => {});
        }
    },
});
