
import Vue from 'vue';

export default Vue.extend({
    name: 'BudgetDropdown',
    props: ['selectedOptionId'],
    data() {
        return {
            isOpen: false,
            data: [
                {
                    id: 'Moins de 2000 €',
                    name: 'Moins de 2000 €',
                },
                {
                    id: 'Entre 2000 et 2500 €',
                    name: 'Entre 2000 et 2500 €',
                },
                {
                    id: 'Entre 2500 et 3000 €',
                    name: 'Entre 2500 et 3000 €',
                },
                {
                    id: 'Plus de 3000 €',
                    name: 'Plus de 3000 €',
                },
            ],
        };
    },
    computed: {
        placeholderText: function (): any {
            return this.selectedOption ? this.selectedOption.name : 'Choisissez votre budget';
        },
        selectedOption: function (): any {
            let optionFound = this.data.find((option: any) => {
                if (option.id == this.selectedOptionId) {
                    return option;
                }
            });
            return optionFound || null;
        },
    },
    methods: {
        selectedItem(selectedItem: any) {
            this.$emit('update:selectedOptionId', selectedItem.id);
            setTimeout(() => {
                this.isOpen = false;
            }, 0);
        },
        closeDropdown(e: MouseEvent) {
            if (this.$refs.dropdown) {
                let targetElement: Node = e.target as Node;
                if (!(this.$refs.dropdown as any).contains(targetElement) && this.isOpen) {
                    this.isOpen = false;
                }
            }
        },
    },
    mounted() {
        document.addEventListener('click', this.closeDropdown.bind(this));
    },
    activated() {
        document.addEventListener('click', this.closeDropdown.bind(this));
    },
    destroyed() {
        document.removeEventListener('click', this.closeDropdown.bind(this));
    },
    deactivated() {
        document.removeEventListener('click', this.closeDropdown.bind(this));
    },
});
