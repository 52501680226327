import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';
import { useBlogStore } from '@/store/blogStore';

Vue.use(VueMeta);

Vue.use(VueGtag, {
    config: { id: 'AW-1111111' },
});

Vue.config.productionTip = false;

// Préchargement des articles de blog
const { fetchArticles } = useBlogStore();
fetchArticles().then(() => {
    new Vue({
        router,
        render: (h) => h(App),
    }).$mount('#app');
});
fetchArticles();
