
import { Component, Vue } from 'vue-property-decorator';
import axios from 'axios';

@Component
export default class NewsletterBanner extends Vue {
    private showBanner: boolean = false;
    private email: string = '';
    private subscriptionStatus: { type: string; message: string } | null = null;

    mounted() {
        if (!this.hasSubscribed()) {
            setTimeout(() => {
                this.showBanner = true;
            }, 5000);
        }
    }

    private hasSubscribed(): boolean {
        return localStorage.getItem('newsletterSubscribed') === 'true';
    }

    get isValidEmail(): boolean {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(this.email);
    }
    private async subscribe(): Promise<void> {
        if (!this.isValidEmail) {
            return;
        }

        try {
            let url = 'https://urgence-maison-de-retraite.fr/mail_FUS.php';
            const response = await axios.post(
                url,
                { email: this.email },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Vérifiez si la réponse contient les données attendues
            if (response.data && typeof response.data === 'object') {
                if (response.data.success) {
                    this.subscriptionStatus = {
                        type: 'success',
                        message: response.data.message || 'Inscription réussie !',
                    };
                    localStorage.setItem('newsletterSubscribed', 'true');
                    setTimeout(() => this.closeBanner(), 2000);
                } else {
                    this.subscriptionStatus = {
                        type: 'error',
                        message: response.data.message || "Erreur lors de l'inscription. Veuillez réessayer.",
                    };
                }
            } else {
                // La réponse n'est pas dans le format attendu
                throw new Error('Réponse invalide du serveur');
            }
        } catch (error) {
            console.error("Erreur lors de l'inscription:", error);
            this.subscriptionStatus = { type: 'error', message: "Erreur lors de l'inscription. Veuillez réessayer." };
        }
    }

    private closeBanner(): void {
        this.showBanner = false;
    }
}
