
// @ts-ignore
import VueSlickCarousel from 'vue-slick-carousel';
import Vue from 'vue';
import { default as partnersJson } from '../../../assets/partners.json'; //List of Partners

export default Vue.extend({
    name: 'Partners',
    components: {
        VueSlickCarousel,
    },
    data() {
        return {
            carouselSettings: {
                responsive: [
                    {
                        breakpoint: 1924,
                        settings: {
                            arrows: false,
                            dots: true,
                            focusOnSelect: true,
                            infinite: true,
                            autoplay: true,
                            speed: 1000,
                            autoplaySpeed: 5000,
                            slidesToShow: 4,
                            slidesToScroll: 1,
                            touchThreshold: 5,
                            adaptiveHeight: true,
                        },
                    },
                    {
                        breakpoint: 1024,
                        settings: {
                            arrows: false,
                            dots: true,
                            focusOnSelect: true,
                            infinite: true,
                            autoplay: true,
                            speed: 1000,
                            autoplaySpeed: 5000,
                            slidesToShow: 2,
                            slidesToScroll: 1,
                            touchThreshold: 5,
                            adaptiveHeight: true,
                        },
                    },
                    {
                        breakpoint: 720,
                        settings: {
                            arrows: false,
                            dots: true,
                            focusOnSelect: true,
                            infinite: true,
                            autoplay: false,
                            speed: 1000,
                            autoplaySpeed: 5000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            touchThreshold: 5,
                            adaptiveHeight: true,
                        },
                    },
                ],
            },
            partners: [],
        };
    },
    methods: {
        getImgUrl(partner: any) {
            //If the image is hosted in another website use property 'fullImgSrc' instead 'imgSrc' 
            if (partner.fullImgSrc) {
                return partner.fullImgSrc;
            }
            let images = require.context(
                '../../../assets/imgs/partners',
                false,
                /\.png$/
            );
            return images('./' + partner.imgSrc);
        },
    },
    beforeMount() {
        this.partners = partnersJson;
    },
});
