import Vue from 'vue';

interface BlogArticle {
    id: number;
    title: string;
    content: string[];
    image: string;
}

const state = Vue.observable({
    blogArticles: [] as BlogArticle[],
});

export const useBlogStore = () => ({
    blogArticles: state.blogArticles,
    getArticleById: (id: number) => state.blogArticles.find((article) => article.id === id),
    fetchArticles: () => {
        // Simuler un chargement asynchrone
        return new Promise<void>((resolve) => {
            setTimeout(() => {
                state.blogArticles = [
                    {
                        id: 1,
                        title: 'Portrait d’AS : Florence Gacogne nous livre le fruit de son expérience',
                        content: [
                            'Le métier d’assistant (e) de service social se renouvelle sans arrêt, notamment parce qu’il implique une relation au plus près de l’humain et de ses besoins. Florence Gacogne l’exerce depuis près de vingt ans au sein de la même clinique privée. Elle nous fait part de sa grande expérience dans le domaine. Si son quotidien apporte constamment son lot de défis et de nouveautés, elle est souvent confrontée à certaines problématiques ou non-sens administratifs qu’elle tente de dénouer pour apporter aux familles et aux personnes fragilisées la solution qui correspond le mieux à leurs besoins.',

                            'Le métier d’assistant de service social est une véritable vocation. On l’exerce rarement par hasard. On le choisit souvent parce que l’on est animé par cette envie de faire du bien aux autres, de se confronter à la réalité humaine et aux besoins d’autrui. C’est après des années de bons et loyaux services en tant qu’assistante de direction au sein d’entreprises privées et à 38 ans que Florence décide d’entamer un nouveau tournant dans sa carrière et dans sa vie en entreprenant des études d’assistante de service social. Depuis, elle n’a jamais quitté son poste au sein de l’unité gériatrique d’une clinique privée de convalescence et soins de suite. Voilà maintenant près de 20 ans, passés auprès des personnes âgées, à les écouter, à les aider, à les aiguiller. En collaboration avec les médecins, les acteurs du secteur et les administrations, elle organise les retours à domicile, les aides techniques, financières et humaines ou la recherche d’un établissement adapté, une résidence seniors ou un EHPAD.',

                            'France Urgence Seniors, une aide précieuse pour les assistant(e)s de service social',

                            'Afin d’aider la personne à trouver l’établissement le plus approprié à ses envies et à ses besoins, elle tient compte du niveau d’autonomie, du budget, de l’emplacement souhaité. C’est dans le cadre de cette recherche que France Urgence Seniors, le pôle pro d’Assistance Retraite, a l’occasion de l’accompagner dans ses recherches. “Lorsque les familles souhaitent un établissement à proximité de mon secteur et que je connais bien les maisons, je peux les guider. Mais, souvent, il s’agit d’autres emplacements plus éloignés et j’ai vraiment besoin des services de France Urgence Seniors pour m’aider à trouver l’établissement qui convient à tous les critères. Parfois, je demande moi-même de l’aide pour les recherches ou j’envoie directement les familles vers les conseillers de FUS,” confie Florence.',

                            'Contourner certains non-sens administratifs',

                            'Comme bon nombre de professionnels du secteur, Florence rencontre parfois certaines situations plus compliquées à gérer. C’est notamment le cas lorsqu’une entrée en EHPAD apparaît comme la meilleure solution pour la personne mais que de longues procédures administratives et juridiques retardent cette opportunité pour l’intéressé. Florence a choisi de nous exposer deux cas de figure, révélateurs d’un réel écart entre les solutions juridiques et les besoins sur le terrain.',

                            'Comment faire, lorsqu’une personne atteinte d’Alzheimer est complètement isolée en attente d’une mesure de protection juridique ?',

                            '“J’ai rencontré par exemple le cas d’un patient qui est arrivé à la clinique sans famille, ni entourage et avec des troubles cognitifs. Dans ce cas, il ne pourra pas rentrer à domicile. On lance alors une mesure de protection juridique et l’on signale la situation au Procureur de la République qui saisit un mandataire judiciaire. Mais, en attendant que toutes ces démarches aboutissent à une décision, même si le patient est d’accord d’intégrer un EHPAD, il est très difficile de trouver un établissement qui accepte de l’accueillir, sans savoir encore comment seront réglés les frais d’hébergement. Dans l’intervalle, le patient est donc contraint de rester hospitalisé jusqu’à ce qu’un mandataire judiciaire soit désigné.” Il n’existe pas de solution adaptée à ces personnes et ils peuvent rester des mois hospitalisés faute d’une solution rapide pour pouvoir les faire entrer en EHPAD”, confie Florence.',

                            'Lorsque la personne est sous tutelle, il est nécessaire d’obtenir son consentement pour la faire entrer en EHPAD. Cependant, selon l’évolution de la maladie, celle-ci n’est justement pas en mesure de le donner et le tuteur ne peut pas décider d’un placement sans autorisation préalable du juge des tutelles. Dans la majorité des cas, la procédure aboutit effectivement à un placement en EHPAD mais, dans l’intervalle, la personne se retrouve sans solution d’hébergement et doit rester hospitalisée parce qu’un retour à domicile dans des conditions optimales n’est pas envisageable. “ Un jour, j’ai même eu le cas d’une dame atteinte d’Alzheimer et sous tutelle qui est entrée en EHPAD avec l’accord du juge. Pourtant, peu de temps après, le directeur m’a informée qu’il ne pouvait pas la garder parce qu’elle ne voulait pas rester. Elle est revenue chez nous. Le juge des tutelles a demandé une nouvelle expertise médicale puisque celle-ci est valable cinq ans. Le médecin a confirmé la nécessité d’un placement d’office parce que la personne se mettait en danger. Dans de pareils cas effectivement, la liberté de choix de la personne n’est pas valable parce qu’il y a mise en danger. Lorsque l’on se trouve à la limite de savoir si la personne a encore la capacité d’affirmer sa volonté ou non c’est plus compliqué, il faut évaluer la situation au cas par cas.”',

                            "Ces situations difficiles montrent qu’il est essentiel de trouver un équilibre entre le bien de la personne et le respect de sa volonté. Si Florence fait bien attention à ces deux impératifs, elle tente d’aider les concernés et les familles d’une autre façon, en proposant par exemple de tester d’abord un séjour temporaire en EHPAD, ce qui permet généralement de convaincre la personne plus facilement. D’après Florence, les personnes qui entrent en séjour temporaire sont généralement contentes et désirent ensuite rester au sein de l'établissement.",

                            'Lorsque la demande d’ASH complique l’entrée en EHPAD',

                            "Florence attire également notre attention sur la question des entrées en EHPAD dans le cadre d’une demande d’ASH (Aide sociale à l’hébergement) et les problèmes que cela peut poser, une situation qu’elle rencontre souvent. “Lorsqu’il n’y a ni argent, ni descendants, la situation est plus simple parce que l’on sait que l’ASH va être accordée. Sinon, le département pourra éplucher les finances de tous les obligés alimentaires et vérifier le patrimoine avant de décider d’accorder l’ASH. Parfois, s’il n’y a pas de grande différence entre le coût de l’établissement et le montant que les obligés peuvent réunir, je leur conseille plutôt de s'arranger entre eux et de ne pas faire de demande d’ASH, pour gagner du temps et éviter les procédures compliquées”, précise Florence.",

                            'Ce qu’il est important de préciser, c’est que, en attendant la notification de l’Aide, la personne ne peut pas intégrer d’établissement, même si elle se trouve en situation d’urgence. Avant de pouvoir accepter un nouveau résident au titre de l’ASH, les établissements demandent la notification de l’aide sociale pour s’assurer que les frais d’hébergement seront bien réglés. Mais, pour accorder l’aide sociale, le département exige une preuve d’inscription au sein de l’EHPAD. “C’est le poisson qui se mord la queue”, souligne l’assistante sociale.',

                            "Dans la pratique, elle met son expérience et son savoir-faire au service des patients et de leur bien-être, afin de faire accélérer les procédures, de faire accepter certains compromis des deux côtés, d’obtenir l’accord des directeurs dès lors que le dossier est déposé en mairie. “On essaie vraiment d’être à l'écoute des demandes. Chaque cas est particulier, chaque histoire familiale est unique. Il faut être très clair sur ce que l’on peut proposer, ce qu'il est nécessaire de faire. Heureusement que l’on a la chance d’être assisté dans beaucoup de domaines.”",

                            'On retrouve dans le témoignage de Florence une véritable dose d’humanité, de “cas par cas” et d’empathie. Elle déplore hélas le fait que son travail n’est pas toujours apprécié à sa juste valeur et que ses efforts peuvent être perçus comme un dû, une situation que déplorent de plus en plus de professionnels du secteur médico-social. Pourtant, elle continuera de donner le meilleur d’elle-même. Si elle rappelle que la solution miracle n’existe pas, elle fait tout pour s’en rapprocher.',

                            'On souhaite une bonne continuation à Florence dans ce beau métier. Parmi d’autres, France Urgence Seniors se tient à la disposition de Florence et de tous les assistants et assistantes de service social pour leur permettre de trouver la meilleure solution dans les plus brefs délais.',
                        ],
                        image: require('@/assets/imgs/blog/article1.jpg'),
                    },
                    {
                        id: 2,
                        title: 'Demande d’ASH : l’obligation alimentaire des petits-enfants supprimée par la loi bien vieillir',
                        content: [
                            'Les assistant(e)s de service social jouent un rôle essentiel auprès des familles et des patients lors de procédures d’ASH dans le cadre d’une entrée en EHPAD. Recommander un arrangement à l’amiable lorsque c’est préférable, résoudre les conflits, rassurer les familles, obtenir un accord d’entrée de la part des établissements et la liste est longue. Lorsque l’obligation alimentaire entre dans le cadre de l’ASH, la procédure est d’autant plus complexe. Si les enfants sont toujours obligés de participer, une nouvelle loi supprime cette obligation pour les petits-enfants.',

                            'Rappelons que l’obligation alimentaire est une disposition légale visant à protéger une personne âgée de la précarité et permettant notamment d’aider au financement de son hébergement en maison de retraite. L’obligation alimentaire peut être réglée à l’amiable si la personne âgée et les obligés alimentaires parviennent à se mettre d’accord sur les besoins et la répartition des montants que chacun peut donner. Si toutefois ils ne parviennent pas à un tel accord, il est possible de faire appel à un juge aux affaires familiales qui évalue les besoins de la personne et les capacités financières de chaque obligé alimentaire.',

                            'Dans le cadre de l’ASH, le département évalue souvent les possibilités financières des obligés alimentaires avant de définir le montant de l’aide. La question de la participation des obligés alimentaires est donc particulièrement épineuse lors d’une demande d’aide sociale à l’hébergement. Souvent perçue comme une lourde tâche pour les familles, cette loi vient alléger la participation financière d’une partie des descendants en supprimant l’obligation alimentaire des petits-enfants dans le cadre d’une demande d’ASH.',

                            'Quels sont les avantages et les limites de cette nouvelle loi?',

                            'Les cas d’entrée en établissement (USLD, résidence-autonomie et EHPAD) font très souvent le quotidien des assistant(e)s de service social. Selon les cas, chaque dossier implique une série de tâches pour lesquelles l’expérience et les capacités d’adaptation, de persuasion et de jugement personnel du professionnel sont souvent mises à l’épreuve. Les familles sollicitées ne sont pas toujours d’accord de payer, font parfois face à une problématique de récupération de l’ASH sur la succession et à une situation difficile moralement que le professionnel doit les aider à surmonter tout en faisant son maximum pour trouver une solution optimale pour la personne âgée et faire face aux refus des établissements frileux lorsqu’une demande d’ASH a été faite mais n’a pas encore aboutie. Rappelons en effet que dans le cas où une demande d’Aide sociale à l’hébergement a été effectuée pour financer un hébergement en maison de retraite, le conseil départemental peut choisir de faire appel aux obligés alimentaires avant de financer le solde restant. Si certains conseils départementaux, dans la pratique, renoncent à cette possibilité, ce n’est pas le cas de tous les départements et la famille d’une personne âgée est encore souvent mise à contribution lorsqu’une demande d’aide sociale à l’hébergement est effectuée.',

                            'Ces changements interviennent dans le cadre de la proposition de loi consacrée au “bien vieillir” et plus particulièrement à l’article 9 de cette loi qui a été adoptée définitivement le 27 mars dernier.',

                            'La liste des obligés alimentaires a été modifiée dans certains cas puisque l’obligation alimentaire concernant les petits-enfants a été supprimée de la loi pour les départements où les membres de la famille sont encore sollicités lors d’une demande d’ASH.',

                            'La loi supprime ainsi les petits-enfants de la liste des obligés alimentaires sollicités lors d’une demande d’ASH, ce qui est une bonne nouvelle pour les familles et devraient réduire les conflits internes. Une petite-fille ou un petit-fils ne doivent plus payer pour le papi ou la mamie qui entre en EHPAD. Nouvelle disposition légale : les petits-enfants exemptés d’obligation alimentaire lors d’une demande d’ASH.',

                            'La suppression de l’obligation alimentaire pour les petits-enfants concernant l’hébergement en maison de retraite de leur grand-parent devrait avoir plusieurs effets et notamment la valorisation du pouvoir d’achat des jeunes et la diminution des conflits familiaux que pouvait poser ce dispositif. Par ailleurs, ces changements devraient également inciter davantage de personnes âgées à faire une demande d’aide sociale à l’hébergement. En effet, comme l’a souligné la ministre Aurore Bergé, «Régulièrement, des personnes refusent d’entrer en Ehpad et préfèrent renoncer à une place plutôt que de courir le risque de voir leurs petits-enfants sollicités financièrement. C’est cela que l’article 9 empêchera».',

                            'Si cette loi semble ouvrir la voie à des solutions concrètes, les mesures qu’elles apportent paraissent bien légères au vu de la réalité du terrain. Non seulement, elle ne concerne pas l’obligation alimentaire en dehors d’une demande d’ASH qui incombe toujours aux petits-enfants et ne prévoit aucun allègement pour les enfants dont l’un ou les deux parents n’ont pas les moyens de financer leur hébergement en EHPAD.',
                        ],
                        image: require('@/assets/imgs/blog/article2.jpg'),
                    },
                    {
                        id: 3,
                        title: 'La CNIL délibère concernant les dispositifs de vidéosurveillance dans les chambres des EHPAD',
                        content: [
                            'La question de la vidéosurveillance en EHPAD est revenue sur le devant de la scène, notamment à la suite de la parution du livre “Les Fossoyeurs” qui a remué l’opinion publique et le secteur de l’hébergement pour personnes âgées. Dans l’une de ses délibérations, la Cnil précise les conditions d’installation et d’utilisation de dispositifs de vidéosurveillance dans les espaces communs des EHPAD et surtout au sein des chambres des résidents.',

                            'Suite aux aléas qu’ont connus les établissements d’hébergement pour personnes âgées concernant la problématique des maltraitances, plusieurs initiatives visant à accroître la transparence et la protection des personnes âgées ont été lancées par les pouvoirs publics et les établissements eux-mêmes. Si la confiance a sans doute été ébranlée, il est important pour les professionnels du secteur médico-social de pouvoir rassurer les familles autant que possible.',

                            'L’installation de dispositifs de vidéosurveillance dans les chambres des résidents d’EHPAD peut représenter dans certains cas un outil pour confirmer ou infirmer certains soupçons et protéger les personnes vulnérables. Cependant, elle soulève également plusieurs questions éthiques et relatives au respect de la vie privée des personnes âgées et des professionnels de l’établissement. La Commission nationale de l’informatique et des libertés (Cnil) s’est prononcée sur toutes ces questions pour nous apporter des réponses claires et précises qui peuvent aider les familles à connaître leurs droits et ceux des résidents, tout en fournissant de précieux outils aux professionnels du secteur médico-social pour les accompagner de manière optimale et les rassurer.',

                            'Les réponses apportées par la Cnil figurent dans sa “Délibération n° 2024-024 du 29 février 2024 portant adoption d’une recommandation relative à la mise en place de dispositifs de vidéosurveillance au sein des chambres des établissements accueillant des personnes âgées”, publiée le 2 mai dernier dans le Journal officiel.',

                            'Cette délibération fait suite à la demande de plusieurs organismes sociaux et médico-sociaux qui se sont tournés vers la Cnil pour apporter des réponses à la question des possibilités et des conditions de l’installation de dispositifs de surveillance dans les chambres des résidents.',

                            'Rappel des conditions d’installation de caméras dans les espaces communs',

                            'Avant d’aborder la question de la vidéosurveillance en chambre, la Cnil rappelle que l’installation de caméras dans les espaces publics des établissements est soumise aux règles de RGPD et à la loi “informatique et libertés.” Une autorisation spéciale doit également être délivrée par la préfecture du lieu d’implantation du dispositif. Les espaces communs de la résidence ouverts aux résidents, aux familles et au personnel comme la salle à manger, la bibliothèque et les salons, peuvent donc a priori être équipés de dispositifs de vidéosurveillance pour garantir la sécurité des biens et des personnes. Cependant, ils ne doivent pas placer les salariés ou les résidents sous surveillance constante.',

                            'La vidéosurveillance dans les chambres : protéger sans entraver le respect de la vie privée',

                            "L’installation de caméras au sein des chambres où logent les résidents pose davantage de problèmes éthiques. Si l’utilité de ce dispositif n’est pas écartée par la Cnil, elle rappelle toutefois l’importance de respecter l'intimité des résidents et de ne pas mettre les employés sous surveillance constante. Il est nécessaire de trouver un équilibre entre ces deux priorités. Considérant que la vidéosurveillance en chambre porte atteinte à la dignité des personnes hébergées filmées en permanence et soumet les salariés à une surveillance continue, la Cnil pose des conditions très strictes à l’installation de caméras dans les chambres.",

                            'Ainsi :',

                            'La vidéosurveillance est interdite pour augmenter le confort du résident et améliorer le service à la personne, même si ce dernier a donné son consentement.',

                            'La Cnil recommande de privilégier d’autres solutions moins intrusives pour la protection des personnes âgées comme des enquêtes de satisfaction, des cahiers de doléances à destination des personnes hébergées et des familles, des dispositifs d’appel réagissant à la voix ou au geste, des dialogues avec le Conseil de la vie sociale, des capteurs de présence au sol et bracelets pour détecter les chutes…',

                            'Le recours à la vidéosurveillance en chambre doit être réservé à des cas bien précis comme dans le cadre d’une enquête pour maltraitance en cours, en cas de suspicion étayée et si les procédures d’enquêtes internes n’ont pas permis de lever le doute sur une situation de maltraitance ou d’en déterminer l’origine.',

                            'La Cnil rappelle enfin l’impératif de ne pas porter atteinte à la liberté des employés, même si la vidéosurveillance peut servir à contrôler leur travail par l’employeur, afin de protéger les biens et les personnes hébergées. La vidéosurveillance doit être proportionnée et respecter les droits fondamentaux des employés. Avant de mettre en place un système de vidéosurveillance en cas de soupçons avérés de maltraitance, les employés doivent être préalablement avertis tout en leur rappelant les obligations qui incombent à chacun concernant la lutte contre la maltraitance et notamment l’obligation de dénonciation.',
                        ],
                        image: require('@/assets/imgs/blog/article3.jpg'),
                    },
                    {
                        id: 4,
                        title: 'Projet pilote du gouvernement : les infirmiers autorisés à rédiger les certificats de décès',
                        content: [
                            'Pour faire face à des obstacles administratifs et à des délais importants, le gouvernement a lancé un projet pilote pour autoriser les infirmiers diplômés d’Etat portés volontaires à constater les décès à domicile ou en EHPAD et à rédiger le certificat de décès.',

                            'Cette expérimentation prévue par le budget de la sécurité sociale de 2023 a pu être récemment lancée grâce à un décret et deux arrêtés gouvernementaux précisant les conditions de mise en place du projet gouvernemental. Avec un déploiement sur six régions au départ, l’expérimentation s’élargit petit à petit à tout le territoire depuis le 26 avril 2024.',

                            'En quoi consiste l’expérimentation ?',

                            "Le certificat de décès est un document important délivré par un médecin. Il comporte un volet médical et un volet administratif qui fournit notamment les informations nécessaires à la famille pour l’organisation des obsèques. En effet, les familles doivent impérativement disposer de ce document qu’elles remettent à la société des Pompes Funèbres pour pouvoir organiser des obsèques. Cependant, il peut parfois s’écouler un laps de temps relativement long avant qu’elles puissent recevoir ce précieux document, faute de médecin disponible pour le rédiger. Afin d’alléger ces démarches pour les familles et d’accélérer la procédure, le gouvernement a décidé de lancer un projet pilote dans lequel les infirmiers diplômés d'État peuvent également, sous certaines conditions, délivrer la pépite.",

                            'L’expérimentation a d’abord été testée dans six régions pilotes : Auvergne-Rhône-Alpes, Centre-Val de Loire, Ile-de-France; Hauts-de-France, La Réunion, Occitanie, pour être ensuite élargie à l’ensemble du territoire à partir du 26 avril 2024, par le décret n° 2024-375 du 23 avril 2024.',

                            "Dans les faits, “les infirmiers volontaires et inscrits sur une liste peuvent établir, à toute heure et signer le certificat de décès d'une personne majeure, lorsque cette personne est décédée à son domicile ou dans un établissement d'hébergement pour personnes âgées dépendantes, à l'exclusion des situations où le caractère violent de la mort est manifeste.”",

                            'Qui sont les infirmiers autorisés à rédiger les certificats et sous quelles conditions ?',

                            'Pour pouvoir se porter volontaires du projet et être inscrits sur les listes, les infirmiers, salariés ou libéraux, doivent respecter certaines conditions :',

                            'Être diplômé d’Etat',
                            "Être inscrit au tableau de l'ordre et diplômé depuis au moins trois ans",
                            'Recueillir l’accord de son employeur pour l’expérimentation (dans le cas où l’infirmier est salarié)',
                            "Demander au conseil départemental de l'ordre des infirmiers de son lieu de résidence son inscription sur une liste.",

                            "Sur la base d’une inscription des infirmiers volontaires et respectant les critères requis, le conseil départemental de l'ordre des infirmiers établit et met à jour une liste des infirmiers susceptibles d’être contactés en cas de décès. Cette liste est ensuite transmise aux agences régionales de santé territorialement compétentes, aux services d'aide médicale urgente, aux communautés professionnelles territoriales de santé, aux unions régionales des professionnels de santé des médecins libéraux, aux services départementaux d'incendie et de secours et aux services de police et de gendarmerie.",

                            'Les infirmiers volontaires suivent une formation en deux parties, comprenant un premier module “épidémiologie et examen clinique du processus mortel” et un second module administratif et juridique.',

                            "Ils peuvent être sollicités au besoin pour rédiger les certificats de décès, sauf lorsque le caractère violent de la mort est manifeste ou dans d’autres situations exceptionnelles mentionnées dans le code civil. Dans ce cas, l’infirmier s'abstient de constater le décès et contacte le médecin traitant de la personne décédée ou, à défaut, les services d'aide médicale urgente.",

                            'En HAD et en EHPAD',

                            "En service d’hospitalisation à domicile (HAD) et en EHPAD, les infirmiers volontaires pour cette expérimentation et inscrits sur la liste peuvent également constater et certifier un décès pendant leur temps de travail. Selon le cas, ils informent le médecin coordonnateur, le médecin responsable ainsi que le directeur de l'établissement et transmettent les données relatives aux causes du décès au médecin traitant.",

                            'Cette expérimentation est actuellement en cours dans plusieurs régions. Il peut être intéressant pour les professionnels du secteur médico-social d’en informer les familles afin qu’elles puissent en comprendre les tenants et les aboutissants. Ces informations peuvent entrer dans le cadre de l’accompagnement proposé aux familles et à l’aide apportée dans les démarches administratives relatives au décès et à l’organisation des obsèques.',
                        ],
                        image: require('@/assets/imgs/blog/article4.jpg'),
                    },
                    {
                        id: 5,
                        title: 'Mieux comprendre le rôle de la kinésithérapie en gériatrie',
                        content: [
                            "La kinésithérapie occupe une place importante en gériatrie. Elle accompagne les personnes tout au long du vieillissement, à domicile ou en établissement. Cependant, son rôle essentiel à la préservation de l'autonomie, à la prévention des risques de chutes et surtout de rechutes, à la récupération fonctionnelle et à la santé générale des personnes âgées est parfois méconnu ou sous-estimé. En se tenant constamment informé(e)s, les assistant(e)s de service social restent au plus près des besoins des personnes âgées. Eléonore Durand, kinésithérapeute spécialisée en gériatrie et en maintien de l’autonomie chez les personnes âgées, nous explique le métier de kinésithérapie en gériatrie.",

                            'La kinésithérapie, un rôle clé dans la prévention des chutes chez les personnes âgées',

                            'Après des années d’expérience en gériatrie, et notamment au sein de divers SSR gériatriques, EHPAD et clinique spécialisée, Eléonore Durand accompagne aujourd’hui les personnes âgées au sein d’un service SMR (Soins médicaux et de réadaptation) gériatrique axé sur la rééducation motrice, l’autonomie et la préparation des retours à domicile. Elle est également formatrice en école d’aides-soignants et kinésithérapie et dispense des formations continues à des rééducateurs de diverses régions de France.',

                            '“J’ai souvent vu des prises en charge plus que simplistes, comme la « marchothérapie » (NDLR : faire uniquement marcher la personne âgée, sans aucun autre exercice de renforcement musculaire, d’équilibre…). Bien que très intéressante car globale, ce n’est pas suffisant," souligne Eléonore.',

                            'En effet, elle explique que dans le domaine de la prévention des chutes notamment, la prise en charge est axée sur une activité physique régulière parce que le corps “oublie vite” les bons automatismes et a tendance à être fainéant. Or, les professionnels du secteur médico-social savent à quel point les chutes et surtout les rechutes sont à éviter au maximum et représentent l’une des premières causes de perte d’autonomie chez les personnes âgées.',

                            '“Le risque principal pour moi est le temps passé au sol après une chute et l’incapacité à se relever seul. Je vois souvent des complications post-chute parce que les personnes ont passé des heures au sol avant que l’on s’en aperçoive. D’où la nécessité de travailler le relevé du sol, ou au moins la mise en sécurité en attendant les secours. En effet, la position assise vaut mieux que de rester allongé sur place. La téléalarme peut être une solution mise en place par les professionnels”, précise la spécialiste.',

                            'A domicile, le risque de chute réside souvent dans la présence d’objets gênants comme des tapis ou des meubles qui encombrent le passage. Les risques de chute sont aussi plus fréquents à domicile parce que les personnes sont plus actives, ont tendance à réaliser elles-mêmes les tâches du quotidien, même si, pour Eléonore, l’activité en soi est une bonne chose. Elle souligne le fait qu’il existe également des risques de dénutrition et de déshydratation, car il est fréquent que les personnes âgées qui vivent chez elles ne soient pas très enclines à cuisiner et ont tendance à consommer des produits déjà prêts ou qui nécessitent peu de préparation. Elles font ainsi l’impasse sur l’apport en protéine, nécessaire pour le maintien de la masse musculaire.',

                            'Maintenir l’activité physique et la participation des résidents à la vie de l’EHPAD',

                            'En institution, Eléonore pointe un autre problème concernant les risques de chutes : “En milieu hospitalier ou en institution il n’est pas rare que les personnes âgées perdent leur indépendance fonctionnelle, et ce, pas forcément à cause de la pathologie ou du vieillissement. Cela peut être dû à l’hospitalisation ou à l’institutionnalisation elle-même. C’est ce que l’on appelle la dépendance iatrogène : sortir plus dépendants qu’ils ne sont entrés. En effet, dans les services hospitaliers ou en institutions, les personnes âgées sont trop « chouchoutées ». Quasi tout est fait à leur place, et leur activité physique de base diminue considérablement. Elles ne font pas leur lit, leur ménage, le repas, ne réfléchissent même pas au déroulement de leur journée, ou au menu du midi. Il s’ensuit un manque de stimulation physique, un risque de chute plus important et une entrée en dépendance augmentée. Évidemment, l’organisation d’un service, le respect des différents protocoles régissent cette façon de faire. Néanmoins, stimuler la personne âgée à faire plus de choses dans sa vie quotidienne comme faire sa toilette ou faire son lit, la faire participer à son quotidien, permettrait de limiter la dépendance iatrogène et donc le risque de chute. Pour moi, les aides-soignants ont un rôle de rééducateur de la vie quotidienne. Avec une prise en charge en rééducation, il est nécessaire de continuer les efforts durant le reste de la journée.”',

                            'C’est notamment la raison pour laquelle les établissements accordent une place importante aux activités physiques et cognitives proposées dans le cadre du programme d’animations quotidiennes. Elles permettent aux résidents de rester actifs et de préserver leur autonomie. Par ailleurs, la kinésithérapie fait souvent partie intégrante de la prise en charge en EHPAD, généralement en collaboration avec des professionnels libéraux externes.',

                            'En EHPAD, la kinésithérapie accompagne quotidiennement les résidents, de la même manière que lorsqu’ils se trouvent à domicile. En effet, Eléonore insiste sur le fait que les EHPAD sont des lieux de vie à part entière. Le professionnel doit cependant s’adapter à un niveau de dépendance qui est généralement plus élevé en EHPAD. Par ailleurs, le kinésithérapeute en EHPAD accompagne les soignants dans leur prise en charge. “En communiquant sur les capacités de la personne, le kiné va permettre aux autres professionnels d’être les plus pertinents possible dans l’aide apportée. Par exemple, une personne qui est capable de marcher avec le kiné 30m devrait pouvoir aller faire sa toilette en marchant plutôt qu’en utilisant un guidon de transfert. Il a un rôle à jouer dans la coordination des préventions des chutes”, explique Eléonore.',

                            'Elle ajoute que de plus en plus de nouveaux postes sont créés en EHPAD. Il s’agit notamment des kinésithérapeutes coordonnateurs dont le rôle majeur est de travailler en collaboration étroite avec le médecin coordonnateur, afin de coordonner l’ensemble des actes de rééducation dont le résident aura besoin.',

                            'La kinésithérapie en gériatrie : une prise en charge multifonctionnelle et personnalisée',

                            "Eléonore insiste sur le fait que la kinésithérapie pour les personnes âgées à domicile ou en établissement s’adapte à l’évolution des besoins et aux différents degrés de dépendance. “La kinésithérapie accompagne tout au long du vieillissement. Au début, en tant que prévention, par la suite plutôt pour entretenir les capacités, pour éviter l’entrée dans la dépendance, puis pour limiter les complications de la grabatisation (mobilisation pour permettre les soins d'hygiène, drainage bronchique en cas de fausses routes…).”",

                            'Eléonore nous explique l’importance d’une prise en charge personnalisée en kinésithérapie gériatrique : “Il est important d’allier une prise en charge fonctionnelle (basée sur la fonction en elle-même) et globale (basée sur le corps entier, en incluant toutes les pathologies de la personne). En effet, les prises en charge en kinésithérapie sont un peu plus difficiles par leur notion de polypathologies. Ainsi une personne peut présenter des pathologies qui s’additionnent et qui en font un « sujet » à part. Aucun tableau clinique n’est le même, il faut apprendre alors à s’adapter et surtout à observer la personne dans sa globalité.”',

                            "Parmi les nombreux exercices qu’elle recommande dans le cadre de la prise en charge des personnes âgées et la prévention des chutes, Eléonore propose de travailler la marche arrière. En effet, elle explique que le risque de chute est important durant la marche arrière puisque la vision, un des sens qui nous aident à maintenir l’équilibre, n’est pas utilisée. “Pourtant, sans nous en rendre compte, nous marchons souvent en marche arrière : en ouvrant une porte, en allant s'asseoir… Pour prévenir les chutes, il est donc important de travailler la marche arrière. De plus, cet exercice permet un entretien articulaire et musculaire. Enfin, des études montrent aussi qu’il y a un lien entre la diminution de l’extension de hanche et les lombalgies.”",

                            'Si le rôle pourtant essentiel de la kinésithérapie en gériatrie est parfois méconnu ou sous-estimé, Eléonore insiste particulièrement sur l’importance de revaloriser ce métier auprès des jeunes professionnels, une vocation qu’elle s’est donnée au travers des formations qu’elle dispense : “La kinésithérapie en gériatrie ne fait pas rêver les étudiants en général. Je pense qu’ils ne se rendent pas compte à quel point c’est une spécialité avec autant de technicité que peut l’être la kinésithérapie du sport. De plus, dans nos sociétés actuelles, le vieillissement est vu comme une déchéance. Les stéréotypes sont tenaces et n’aident pas à valoriser la gériatrie.”',
                        ],
                        image: require('@/assets/imgs/blog/article5.jpg'),
                    },
                    {
                        id: 6,
                        title: 'Selon ce spécialiste de l’ostéoporose, les assistantes sociales ont un rôle important à jouer',
                        content: [
                            "L'ostéoporose, souvent appelée la « maladie silencieuse », se faufile discrètement dans toutes nos vies. Elle met des années à affaiblir insidieusement les os sans symptômes apparents jusqu'à ce qu'une fracture survienne. Cette maladie est particulièrement préoccupante pour les personnes âgées, car elle peut détruire en un instant leur indépendance et leur autonomie. Mais saviez-vous qu'en tant qu'assistante sociale, vous êtes en première ligne pour aider à détecter, prévenir et gérer cette menace ?",

                            'Pour mieux cerner les risques de l’ostéoporose, les moyens de prévention et le rôle que les assistantes sociales ont à jouer, France Urgence Seniors est partie à la rencontre d’un spécialiste de l’ostéoporose, Luc Lallemand, Docteur du CNAM (2006) et auteur du livre « Ostéoporose et Ostéopénie : quand s’inquiéter et comment réagir ? Guide de reminéralisation naturelle.”',

                            'Selon-vous, les assistantes sociales ont un rôle crucial à jouer concernant l’ostéoporose. Pouvez-vous nous expliquer ?',

                            'Oui, je pense qu’elles peuvent faire une réelle différence dans la vie des personnes qu’elles aident, dans la vie de leurs proches et même dans la leur en informant et en guidant pour prévenir la « fatalité ». Elles peuvent jouer un rôle important aussi bien auprès des personnes âgées, de leur famille que des institutions, entités ou municipalités qu’elles fréquentent ou dans lesquelles elles habitent.',

                            'Vous souhaitez nous raconter une histoire pour présenter les risques de l’ostéoporose.',

                            "Oui, effectivement. Imaginez Claire, une assistante sociale compétente travaillant avec des personnes âgées depuis plus de vingt ans. Un jour, elle rend visite à Madame Duclerc, une veuve de 78 ans, pleine de vie. En entrant, Claire remarque que Madame Duclerc semble plus courbée que d'habitude.",

                            "« J'ai juste fait une mauvaise chute, ce n'est rien », dit Madame Duclerc en souriant.",

                            "Mais Claire sait que ce n'est jamais « rien » avec les personnes âgées : elle conseille à Madame Duclerc d’en parler à son médecin.",

                            "En effet, une chute peut être le début d'un problème bien plus grave.",

                            "Après quelques examens médicaux, le verdict tombe : Madame Duclerc souffre d'ostéoporose. Une simple chute a entraîné un tassement de vertèbre. Madame Duclerc doit désormais prendre un traitement pour éviter d’autres fractures. Heureusement, elle a évité l’opération et l’alitement...",

                            'Pourquoi les assistantes sociales sont-elles concernées lorsque l’on parle d’ostéoporose ?',

                            "Ce sont elles qui sont souvent les premiers témoins des difficultés que rencontrent les personnes âgées. Elles occupent la position idéale pour repérer les signes avant-coureurs des risques de l'ostéoporose. Là encore, j’aimerais vous raconter une histoire : “Monsieur Lemoine, un autre de vos patients, est un ancien professeur des écoles de 72 ans. Il mène une vie relativement sédentaire depuis sa retraite et se plaint souvent de douleurs au dos. En discutant avec lui, vous apprenez qu'il ne sort presque jamais de chez lui, surtout pas pour faire de l'exercice. Vous décidez de lui parler de l'ostéoporose et des risques associés à l'inactivité. Vous lui expliquez que l'ostéoporose est une maladie qui affaiblit les os, les rendant poreux et fragiles. Vous lui indiquez que le manque d’activité physique est un facteur accélérateur de cette affection. Elle touche particulièrement les personnes âgées et peut entraîner des fractures même après des chutes mineures. Il vous dira peut-être que ce sont les femmes qui sont affectées à cause de la baisse des niveaux d'œstrogènes après la ménopause. Alors, vous pourrez lui expliquer que les hommes ne sont pas à l'abri, particulièrement après 70 ans.”",

                            'Quelles sont les stratégies de prévention de l’ostéoporose pour les assistantes sociales ?',

                            'Je pense que les actions principales que les assistantes sociales et acteurs du secteur médico-social peuvent mener pour prévenir les dangers liés à l’ostéoporose sont les suivantes :',

                            "Encourager l'exercice physique :",

                            'Exercices de Résistance : Vous pouvez recommander des exercices de résistance comme le levage de poids progressivement croissant avec un excellent encadrement. Ces exercices renforcent les muscles et les os.',

                            'Exercices à Impact : Des activités comme sauter, danser, monter des escaliers – ou même simplement les descendre – peuvent aider à maintenir la densité osseuse.',

                            "Exercices d'équilibre : Le yoga, le tai-chi ou encore plus simplement la marche peuvent considérablement améliorer l'équilibre et prévenir les chutes.",

                            'Promouvoir une alimentation équilibrée :',

                            'Alimentation riche en nutriments : Incitez vos bénéficiaires et ceux qui sont chargés de leur gastronomie à se rapprocher d’une alimentation de type méditerranéen qui permet d’apporter tous les nutriments indispensables. Il faut simplement vérifier que les apports de calcium, protéines, magnésium et vitamine C sont suffisants.',

                            'Vitamine D : En Europe, un supplément de vitamine D est quasiment indispensable et nécessaire (presque) toute l’année.',

                            'Adopter des habitudes de vie saines :',

                            'Tabac et alcool : Rappelez éventuellement à vos contacts les risques et aidez-les à adopter des habitudes plus saines avec tous les bénéfices qui les accompagnent.',

                            'Inflammation : les pollutions externes, mais aussi internes, aux habitations sont de nature à augmenter l’inflammation chronique, qui elle-même perturbe le fonctionnement osseux. Il est important de bien entretenir son habitation et de l’aérer suffisamment.',

                            "Maintien d'un poids santé : Ni trop mince ni en surpoids. Une alimentation équilibrée et des exercices dynamisants aident à maintenir un poids adéquat.",

                            'Consulter régulièrement :',

                            "Encouragez vos patients à consulter leur médecin pour des contrôles de densité osseuse. Un diagnostic précoce de l'ostéoporose peut permettre de prendre des mesures préventives efficaces y compris dans la prévention des chutes.",

                            'Je recommande également aux assistantes sociales de collaborer étroitement avec les professionnels de santé : médecins, infirmières, nutritionnistes, kinésithérapeutes afin d’offrir un soutien complet à leurs patients. En liaison avec les organismes locaux, elles peuvent également favoriser l’organisation de groupes de soutien ou d’ateliers sur la prévention de l’ostéoporose pour sensibiliser et informer.',

                            'Selon vous, l’ostéoporose ne concerne pas uniquement les personnes âgées.',

                            'Tout à fait. J’adresse mon message également aux assistantes sociales pour leur propre santé osseuse. Passer de longues heures à travailler peut rendre difficile la prise de temps pour soi. Voilà certains conseils que l’on peut appliquer sans attendre l’avancée en âge. Plus on s’y prend tôt et plus la prévention est bénéfique.',

                            "Pratiquer un exercice régulier : Intégrez des activités physiques dans vos habitudes. Une petite promenade pendant la pause déjeuner ou des exercices d'étirement au bureau sont un bon début qui permettra d’aller plus loin, plus haut, plus fort quand vous serez prête.",

                            "Veiller à une alimentation équilibrée : Assurez-vous de manger des repas équilibrés, même pendant les journées chargées. Préparez des collations saines et buvez suffisamment d'eau.",

                            'Que voulez-vous dire aux assistantes sociales ?',

                            "En tant qu'assistantes sociales, vous jouez un rôle crucial dans l'amélioration de la qualité de vie de vos bénéficiaires. En les informant, en les guidant vers des habitudes de vie saines et en les soutenant dans leurs démarches de prévention, vous pouvez faire une énorme différence. Prenez soin de vos propres os et utilisez vos connaissances pour aider ceux que vous servez à rester forts, actifs et indépendants.",

                            "L'ostéoporose est une maladie sérieuse, mais avec une prévention adéquate, ses conséquences peuvent être considérablement atténuées. Il est même possible d’arrêter et d’inverser la déminéralisation osseuse si on s’y prend bien et à temps. Ensemble, faisons de la santé osseuse une priorité pour tous.",

                            'Bref, faisons de vieux os… solides et heureux !',

                            'Les 3 missions essentielles des assistantes sociales concernant l’ostéoporose, selon Luc Lallemand :',

                            "1. Prévention des fractures : Les fractures dues à l'ostéoporose, comme celle de Madame Duclerc, peuvent gravement affecter l'autonomie des personnes âgées. Une fracture de la hanche ou de vertèbres, par exemple, peut conduire à une perte d'indépendance, voire à une hospitalisation prolongée et à des complications graves. 20 % des personnes fracturées décèdent d’ailleurs dans l’année qui suit.",

                            '2. Amélioration de la qualité de vie : En aidant les personnes à adopter des comportements préventifs, vous leur offrez la possibilité de maintenir leur qualité de vie en vieillissant. L’ostéoporose peut limiter la mobilité, mais les bonnes pratiques et habitudes peuvent maintenir les os en bonne santé.',

                            "3. Soutien aux proches : Lorsqu'une personne âgée est diagnostiquée avec de l'ostéoporose, cela affecte toute la famille. En travaillant avec les proches, vous pouvez les aider à comprendre la maladie et à soutenir efficacement leur parent ou ami. Ils ont eux aussi besoin d’être épaulés et informés.",
                        ],
                        image: require('@/assets/imgs/blog/article6.jpg'),
                    },
                    {
                        id: 7,
                        title: 'Les séjours temporaires en sortie d’hospitalisation recadrés par la DGCS',
                        content: [
                            "Une note d’information de la DGCS du 8 juillet 2024, publiée dans le bulletin officiel santé du 15 juillet, à l’attention des directeurs généraux des agences régionales de santé (ARS), précise certaines orientations nationales concernant l’accompagnement des personnes âgées et le dispositif d'hébergement temporaire en sortie d'hospitalisation (HTSH) en Ehpad. Elle prévoit notamment 'des critères d'exclusion pour mieux réguler l'admission'.",

                            "Dans sa note d’information, la DGCS expose le contexte et la nécessité de mesures d’encadrement et de coordination à l’échelle nationale : 'Les personnes âgées de 60 ans et plus sont au nombre de 15 millions aujourd’hui. Elles seront 20 millions en 2030 et près de 24 millions en 2060. Le nombre des plus de 85 ans passera de 1,4 million aujourd’hui à 5 millions en 2060. Face à ce constat, une réponse globale et décloisonnée de l'accompagnement des personnes âgées doit être apportée et mieux tenir compte des parcours de celles-ci entre les structures sanitaires, médico-sociales et les soins en ambulatoire en favorisant le retour au domicile.'",

                            'Rappel des objectifs du dispositif HT-SH',

                            "Rappelons que le dispositif HTSH a pour vocation de proposer aux personnes âgées en perte d’autonomie, sortant des urgences ou d’hospitalisation et ne relevant plus de soins médicaux hospitaliers, un hébergement temporaire d'une durée maximale de 30 jours, avant la réintégration de leur domicile dans un cadre sécurisé ou leur orientation vers une nouvelle structure d’accueil. Ce dispositif a ainsi pour but de faciliter la transition entre l'hôpital et le retour à domicile ou l'entrée dans un établissement adapté, tout en assurant un suivi médical et social approprié pendant la convalescence.",

                            "'La part importante des personnes âgées parmi les personnes hospitalisées ainsi que les effets délétères des hospitalisations sur la perte d’autonomie et la capacité à se maintenir chez soi nécessitent une réponse adaptée', précise la DGCS.",

                            "La note d’information de la DGCS a pour objectif d’apporter une clarification nationale à l’offre HT-SH, reconnue par les ARS comme une solution qui mérite d’être davantage déployée. À ce titre, elle rappelle l’utilité du dispositif HTSH : 'L’objectif principal de l’hébergement temporaire en sortie d’hospitalisation (HT-SH) est de faciliter les sorties d’hospitalisation des personnes âgées en perte d’autonomie en améliorant et en sécurisant les retours à domicile. Ce dispositif permet également de limiter les durées moyennes de séjour à l’hôpital et les réhospitalisations évitables, en s’appuyant sur des places bénéficiant d’un encadrement médico-social.'",

                            'La DGCS ajoute que le dispositif permet de compenser la majeure partie du reste à charge des personnes âgées accueillies sur des places d’hébergement temporaire en le ramenant au montant du forfait hospitalier.',

                            "La feuille de route grand-âge et autonomie, annoncée le 30 mai 2018, avait déjà permis en 2019 de généraliser l’offre d’HT-SH dans chaque département au niveau national, avec le financement de 1 000 places. Depuis, des financements complémentaires pérennes ont permis le déploiement de près de 1 500 places sur le territoire national. 'Afin d’assurer la pérennisation de l’offre, le financement a été rattaché à l’objectif national des dépenses d’assurance maladie (ONDAM) personnes âgées à partir de 2022. À cette occasion, le financement du dispositif a été renforcé avec 8 M€ supplémentaires soit 24 M€ au total.'",

                            'Quels peuvent être les bénéficiaires du dispositif ?',

                            "La DGCS définit clairement les situations qui peuvent donner droit au dispositif d’HT-SH en excluant d'autres cas de figure. Ainsi, deux types de raisons peuvent donner lieu à un séjour temporaire :",

                            '1. En cas de sortie d’hospitalisation prioritairement : des personnes âgées de 60 ans et plus ne relevant plus de soins médicaux hospitaliers, présentant une limitation ou une perte de leur capacité d’accomplir les gestes de la vie quotidienne rendant complexe le retour à domicile à très court terme (adaptation du plan d’aide, aménagement du logement, particulièrement si elles sont seules ou isolées).',

                            '2. En cas de carence soudaine de l’aidant : des personnes âgées de 60 ans et plus ne pouvant se maintenir seules à leur domicile en cas d’absence soudaine de l’aidant (en cas d’hospitalisation non programmée ou de décès par exemple).',

                            'Exclusion de certaines situations au dispositif d’hébergement temporaire en sortie d’hospitalisation',

                            "Cependant, la note ne manque pas de mentionner l’importance pour les ARS de définir des critères d’exclusion, afin de permettre le bon fonctionnement du dispositif et de ne pas confronter les EHPAD qui y participent à des situations inappropriées : 'Avec l’accord de l’ARS, des critères d’exclusion peuvent être fixés au niveau des EHPAD engagés dans le dispositif pour mieux réguler l’admission dans le dispositif. À titre d’exemple : l’absence de médecin traitant, la nécessité de soins techniques infirmiers, notamment la nuit, la non-stabilisation de l’état de santé, la présence de troubles sévères du comportement… La définition de critères d’admission et leur respect par les adresseurs sont des leviers essentiels pour ne pas mettre en difficulté l’EHPAD à travers l’accueil de personnes dont l’état de santé ne pourrait être pris en charge ou pour lesquelles la sortie d’hébergement temporaire vers le domicile ne serait pas envisageable à l’issue de la durée maximale de séjour. Leur respect participe également à instaurer et entretenir une relation de confiance entre les EHPAD et les adresseurs, et à contribuer, sur le long terme, à la fluidification du processus d’orientation et à l’efficacité du dispositif.'",

                            'La note ajoute que certaines situations ne peuvent justifier le recours au dispositif HT-SH comme le seul besoin de mettre en place une aide humaine au domicile (APA, soins infirmiers). Dans ce cas, les services hospitaliers doivent privilégier d’autres solutions comme une demande d’APA en urgence.',

                            "Par ailleurs, le dispositif d’hébergement temporaire en sortie d’hospitalisation est réservé à une 'carence soudaine de l’aidant' et ne peut pas être utilisé comme solution de répit de l’aidant. 'C’est l’hébergement temporaire classique qui remplit cet objectif et qui doit être mobilisé à cette fin.'",

                            'Les conditions de mise en œuvre du dispositif',

                            "Pour la mise en place du dispositif HT-SH, les ARS sont chargées de sélectionner les EHPAD en capacité de proposer le dispositif d’hébergement temporaire en sortie d’hospitalisation. Afin de faciliter et d’anticiper l’orientation vers le dispositif, l’établissement de santé, en cas d'hospitalisation, ou le médecin traitant, en cas de carence de l’aidant, sont chargés de procéder à une évaluation des besoins de la personne âgée. La DGCS précise en effet : 'Ce type de dispositif nécessite une collaboration étroite entre l’EHPAD porteur et les adresseurs potentiels, qu’ils soient professionnels du secteur sanitaire, médico-social, ambulatoire ou autre.'",

                            'Si l’orientation en hébergement temporaire en sortie d’hospitalisation doit être adaptée aux besoins et aux souhaits de la personne et que son consentement ou celui de son représentant légal doit être recueilli par l’adresseur et l’EHPAD qui l’accueille, au sein de l’EHPAD d’accueil la décision d’admission revient au directeur, sur avis du médecin coordonnateur.',

                            'La prise en charge en hébergement temporaire en sortie d’hospitalisation est limitée à une durée maximale de 30 jours par bénéficiaire avant :',

                            '1. la réintégration du domicile dans un cadre sécurisé',
                            '2. l’orientation vers une nouvelle structure d’accueil',
                            '3. la prolongation du séjour sur une place d’hébergement temporaire classique',

                            'Comment se compose le financement du dispositif ?',

                            'Le financement comprend deux parties :',

                            '1. une part forfaitaire attribuée par place pour financer le(s) professionnel(s) chargé(s) de la coordination et de l’accompagnement spécifique attendu sur le dispositif et les frais liés au transport vers le domicile si nécessaire.',

                            '2. une part liée à l’activité d’hébergement via la prise en charge essentiellement d’une partie ou de la totalité du tarif hébergement revenant à l’usager et une partie du tarif dépendance.',

                            'Par ailleurs, la durée maximale de la prise en charge financière, qu’elle soit totale ou partielle, est de 30 jours par bénéficiaire et par séjour.',

                            'À l’issue de la période des 30 jours, si le bénéficiaire n’a pas quitté le dispositif, le tarif hébergement appliqué est celui habituellement pratiqué par le porteur sur les places d’hébergement temporaire. Il est néanmoins possible de mobiliser l’aide sociale à l’hébergement dans le cadre d’une prolongation de l’hébergement.',

                            "'Le financement du prix de journée a pour but de ramener le reste à charge journalier pour le résident à un niveau ne pouvant dépasser le montant du forfait journalier hospitalier, soit, au maximum, 20 € par jour en 2024 contre environ 70 € en moyenne au niveau national. L’ARS peut, le cas échéant, décider d’assurer une compensation intégrale du reste à charge.'",

                            'Le financement versé par l’ARS au titre de l’hébergement temporaire en sortie d’hospitalisation à l’EHPAD intervient en complément du financement habituel versé sur l’hébergement temporaire.',
                        ],
                        image: require('@/assets/imgs/blog/article7.jpg'),
                    },
                ];
                resolve();
            }, 100); // Simule un délai de chargement de 100ms
        });
    },
});
