export const EstablishmentType: any = {
    medical: {
        id: 1,
        value: 'EHPAD',
        text: 'EHPAD',
    },
    alzheimer: {
        id: 2,
        value: 'EHPAD Alzheimer',
        text: 'EHPAD Alzheimer',
    },
    residence: {
        id: 3,
        value: 'Résidence services',
        text: 'Résidence services',
    },
    autonomie: {
        id: 4,
        value: 'MAD',
        text: 'MAD',
    },
};
