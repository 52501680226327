
import Map from '@/components/Map.vue';
import Vue from 'vue';
import { EstablishmentType } from '@/models/establishmentType';
import { generateRandomNumber } from '@/models/randomPlaces';

export default Vue.extend({
    name: 'Main',
    components: {
        Map,
    },
    data() {
        return {
            maxSelectedDepartments: 8,
            disabledBelgium: false,
            establishmentType: EstablishmentType,
            nombrePlaces: generateRandomNumber(244, 247),
            utmParameter: this.$route.query.utm || '', // Récupérer utmParameter à partir de la requête de route
        };
    },
    methods: {
        gotToForm(establishmentTypeVal: any) {
            // Initialiser l'objet pour la redirection avec toutes les propriétés nécessaires
            const routeObj = {
                name: 'Form',
                params: {
                    establishmentType: establishmentTypeVal,
                },
                // Initialiser query comme un objet vide ou avec la propriété utm si utmParameter est présent
                query: this.utmParameter ? { utm: this.utmParameter } : {},
            };
            // Redirection
            this.$router.push(routeObj);
        },
    },
});
